
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { ReactComponent as CommunityLargeIcon } from 'assets/icons/ico_community_large.svg';

import { useMessages } from "context/messages";
import { getToken } from "context/auth";

import {
    getCommunnityConnectRequests,
    getRecipientCommunnityConnectRequests,
    getConnectedCommunitiesForParkingArea,
    getCommunitiesForParkingArea
} from "api/communities";

import Widget from 'components/dashboard/widget';
import WidgetLoadingSkeleton from 'components/dashboard/widgetLoadingSkeleton';
import ConnectedCommunitiesTable from 'components/pageSpecific/communities/connectedCommunitiesTable';
import SentRequestsTable from 'components/pageSpecific/communityConnections/sentRequestsTable';
import ReceivedRequestsTable from "components/pageSpecific/communityConnections/receivedRequestsTable";
import ButtonText from "components/common/buttonText";
import CreateConnectCommunityRequestModal from 'components/pageSpecific/communities/createConnectCommunityRequestModal';

const CommunityConnectionsPage = () => {

    const { t } = useTranslation();

    const { organizationExtId } = useParams();

    const { showToast } = useMessages();
    const [isLoading, setIsLoading] = useState();
    const { parkingAreaExtId } = useParams();
    const [communityConnectRequests, setCommunityConnectRequests] = useState();
    const [recipientCommunityConnectRequests, setRecipientCommunityConnectRequests] = useState();
    const [connectedCommunities, setConnectedCommunities] = useState();
    const [showCreateConnectCommunityRequestModal, setShowCreateConnectCommunityRequestModal] = useState(false);
    const [communities, setCommunities] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        const load = async () => {

            setIsLoading(true);

            try {
                var token = await getToken();
                var communitiesForParkingAreaResult = await getCommunitiesForParkingArea(token, parkingAreaExtId);
                var communityConnectRequestsForParkingAreaResult = await getCommunnityConnectRequests(token, parkingAreaExtId);
                var recipientCommunityConnectRequestsForParkingAreaResult = await getRecipientCommunnityConnectRequests(token, parkingAreaExtId);
                var connectedCommunitiesForParkingAreaResult = await getConnectedCommunitiesForParkingArea(token, parkingAreaExtId);

                if (communitiesForParkingAreaResult && !communitiesForParkingAreaResult.error) {
                    setCommunities(communitiesForParkingAreaResult);
                } else {
                    console.error(communitiesForParkingAreaResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                if (communityConnectRequestsForParkingAreaResult && !communityConnectRequestsForParkingAreaResult.error) {
                    setCommunityConnectRequests(communityConnectRequestsForParkingAreaResult);
                } else {
                    console.error(communityConnectRequestsForParkingAreaResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                if (recipientCommunityConnectRequestsForParkingAreaResult && !recipientCommunityConnectRequestsForParkingAreaResult.error) {
                    setRecipientCommunityConnectRequests(recipientCommunityConnectRequestsForParkingAreaResult);
                } else {
                    console.error(recipientCommunityConnectRequestsForParkingAreaResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                if (connectedCommunitiesForParkingAreaResult && !connectedCommunitiesForParkingAreaResult.error) {
                    setConnectedCommunities(connectedCommunitiesForParkingAreaResult);
                } else {
                    console.error(connectedCommunitiesForParkingAreaResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setIsLoading(false);
        }

        load();
    }, []);

    return (
        <>
            {isLoading &&
                <div className="mt-6">
                    <WidgetLoadingSkeleton />
                </div>
            }
            {!isLoading &&
                <>
                    <Widget>
                        <div className="flex md:flex-row flex-col-reverse gap-12 justify-between items-center">
                            <div className="flex flex-col">
                                <h1 className="text-xl font-medium mb-3">{t('communityConnectionsPage.title')}</h1>
                                <span>{t('communityConnectionsPage.description')}</span>
                            </div>
                            <span className="flex w-20 h-20">
                                <CommunityLargeIcon />
                            </span>
                        </div>
                    </Widget>
                    {(communityConnectRequests?.length > 0 || recipientCommunityConnectRequests?.length > 0) &&
                        <Widget className="mt-6">
                            <div className="flex items-center justify-between">
                                <div className="flex gap-3 items-center">
                                    <div className="flex h-11 w-11 rounded-full bg-airpark-red items-center justify-center text-white font-medium">
                                        <span>{communityConnectRequests?.length + recipientCommunityConnectRequests?.length}</span>
                                    </div>
                                    <span className="font-medium">{t('communityConnectionsPage.pendingRequests')}</span>
                                </div>
                            </div>
                        </Widget>
                    }
                    <Widget className="mt-6">
                        <div className="flex md:items-center items-left flex-col md:flex-row justify-between mb-6 gap-3">
                            <h1 className="text-xl font-medium">{t('communityConnectionsPage.connectedCommunitiesTitle')}</h1>
                            <div className="flex items-center gap-3">
                                <ButtonText onClick={() => { setShowCreateConnectCommunityRequestModal(true); }}>
                                    {t('communityConnectionsPage.connect')}
                                </ButtonText>
                            </div>
                        </div>
                        <ConnectedCommunitiesTable
                            connectedCommunities={connectedCommunities}
                            setConnectedCommunities={setConnectedCommunities} />
                    </Widget>
                    <Widget className="mt-6">
                        <div className="flex items-center justify-between mb-6">
                            <h1 className="text-xl font-medium">{t('communityConnectionsPage.sentRequests')}</h1>
                        </div>
                        <SentRequestsTable
                            sentRequests={communityConnectRequests}
                            setSentRequests={setCommunityConnectRequests}
                        />
                    </Widget>
                    <Widget className="mt-6">
                        <div className="flex items-center justify-between mb-6">
                            <h1 className="text-xl font-medium">{t('communityConnectionsPage.receivedRequests')}</h1>
                        </div>
                        <ReceivedRequestsTable
                            receivedRequests={recipientCommunityConnectRequests}
                            setReceivedRequests={setRecipientCommunityConnectRequests}
                        />
                    </Widget>
                    <CreateConnectCommunityRequestModal
                        display={showCreateConnectCommunityRequestModal}
                        setDisplay={setShowCreateConnectCommunityRequestModal}
                        communities={communities}
                        communityConnectRequests={communityConnectRequests} 
                        setCommunityConnectRequests={setCommunityConnectRequests}/>
                </>
            }
        </>
    );
};

export default CommunityConnectionsPage;