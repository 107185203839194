import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getParkingArea, updateParkingArea, deleteParkingAreaImage } from 'api/locations';

import { ReactComponent as MapAddressIcon } from 'assets/icons/ico_map_address.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/ico_delete.svg';

import { useTranslation } from 'react-i18next';

import CreateParkingAreaMap from 'components/dashboard/parkingAreaMap';
import PageLoad from 'components/common/pageLoad';
import Alert from 'components/common/alert';
import InputText from 'components/common/inputText';
import InputTextArea from 'components/common/inputTextArea';
import ButtonText from 'components/common/buttonText';
import CollapsibleContainer from 'components/common/collapsibleContainer';
import InputFileUpload from 'components/common/inputFileUpload';

import { getToken } from 'context/auth';
import { useMessages } from 'context/messages';
import BackButton from 'components/dashboard/backButton';

const EditParkingAreaPage = () => {

    const { t } = useTranslation();
    const { organizationExtId, parkingAreaExtId } = useParams();
    const { showToast, showBetweenPagesMessage, showModal, hideModal } = useMessages();

    const [parkingArea, setParkingArea] = useState();
    const [showAddressFields, setShowAddressFields] = useState();
    const [friendlyAddress, setFriendlyAddress] = useState();
    const [isSaving, setIsSaving] = useState();
    const [images, setImages] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const load = async () => {
            try {
                const token = await getToken();
                const result = await getParkingArea(token, parkingAreaExtId);

                if (!result.error) {
                    setParkingArea(result);
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
            }
        }

        load();
    }, []);

    const handleParkingAreaNameChange = (value) => {
        setParkingArea({ ...parkingArea, name: value });
    }

    const handleStreetAddressChange = (value) => {
        setParkingArea({ ...parkingArea, address: value });
    }

    const handleCityChange = (value) => {
        setParkingArea({ ...parkingArea, city: value });
    }

    const handleZipCodeChange = (value) => {
        setParkingArea({ ...parkingArea, zipCode: value });
    }

    const handleCountryChange = (value) => {
        setParkingArea({ ...parkingArea, country: value });
    }

    const handleUpdateParkingArea = async () => {

        if (!isFormValid) {
            showToast(t('editParkingArea.fieldsMissingTitle'), t('editParkingArea.fieldsMissingDescription'), 'error');
            return;
        }

        setIsSaving(true);

        try {
            var token = await getToken();
            parkingArea.parkingAreaImageFiles = images;
            var result = await updateParkingArea(token, parkingArea);

            if (!result.error) {

                setParkingArea(result);

                if (result.parkingAreaStatus == 'Approved') {
                    showBetweenPagesMessage(t('editParkingArea.updatedTitle'), t('editParkingArea.updatedDescription'), () => { navigate(`/${organizationExtId}/parking-areas/${parkingArea.parkingAreaExtId.toLowerCase()}`) }, 'success');
                } else {
                    showBetweenPagesMessage(t('editParkingArea.updatedTitle'), t('editParkingArea.updatedDescriptionRequiresApproval'), () => { navigate(`/${organizationExtId}/parking-areas/${parkingArea.parkingAreaExtId.toLowerCase()}`) }, 'info');
                }
            } else {
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        } catch (error) {
            console.error(error);
        }

        setIsSaving(false);
    }

    const deleteImage = async (parkingAreaExtId, fileName) => {
        try {
            const token = await getToken();
            const result = await deleteParkingAreaImage(token, parkingAreaExtId, fileName);

            if (result.error) {
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                console.error(result);
            } else if (result) {
                setParkingArea({ ...parkingArea, images: result });
            }
        } catch (error) {
            console.error(error);
        }

        hideModal();
    }

    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        if (parkingArea && parkingArea.name && parkingArea.polygonCoordinates && parkingArea.polygonCoordinates.length >= 1) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }

        if (parkingArea && !parkingArea.country) {
            setShowAddressFields(false);
            setFriendlyAddress('');
        }

        if (parkingArea && parkingArea.address && parkingArea.city && parkingArea.zipCode) {
            setFriendlyAddress(`${parkingArea.address}, ${parkingArea.zipCode} ${parkingArea.city}`);
        }

    }, [parkingArea]);

    if (parkingArea) {
        return (
            <>
                <BackButton/>
                <div className="flex flex-col m-auto max-w-[768px] w-full">
                    <Alert title={t('editParkingArea.approvalRequiredTitle')} description={t('editParkingArea.approvalRequiredDescription')} template="warning" />
                    <h1 className="mt-14 text-center text-2xl font-medium mb-12">{t('createParkingArea.basics')}</h1>
                    <div className="flex flex-col md:flex-row">
                        <div className="flex flex-col w-full md:w-1/2 md:mr-2">
                            <InputText title={t('createParkingArea.name')} value={parkingArea.name} onChange={(value) => { handleParkingAreaNameChange(value) }} />
                        </div>
                    </div>
                    <div className="flex flex-col mt-6">
                        <p className="text-base font-medium pb-1">{t('createParkingArea.position')}</p>
                        <p className="text-base font-base mb-3">{t('createParkingArea.positionDescription')}</p>
                        <CreateParkingAreaMap parkingArea={parkingArea} setParkingArea={setParkingArea} />
                    </div>
                    <div className="flex flex-col w-full mt-6">
                        <p className="text-base font-medium mb-1">{t('createParkingArea.address')}</p>
                        <div className="flex flex-row items-center">
                            <MapAddressIcon />
                            <p className="ml-3">
                                {!friendlyAddress &&
                                    t('createParkingArea.noAddress')
                                }
                                {friendlyAddress &&
                                    friendlyAddress
                                }
                            </p>
                        </div>
                        {parkingArea.polygonCoordinates && parkingArea.country &&
                            <a
                                onClick={(e) => { e.preventDefault(); setShowAddressFields(!showAddressFields); }}
                                className="text-airpark-green mt-1"
                                href="#">
                                {t('createParkingArea.changeAddress')}
                            </a>
                        }
                        <CollapsibleContainer isExpanded={showAddressFields}>
                            <>
                                <div className="md:flex">
                                    <div className="flex flex-col mt-3 md:w-1/2 md:mr-2">
                                        <InputText title={t('createParkingArea.address')} value={parkingArea.address} onChange={(value) => { handleStreetAddressChange(value) }} />
                                    </div>
                                    <div className="flex flex-col mt-3 md:w-1/2 md:ml-2">
                                        <InputText title={t('createParkingArea.zipCode')} value={parkingArea.zipCode} onChange={(value) => { handleZipCodeChange(value) }} />
                                    </div>
                                </div>
                                <div className="md:flex">
                                    <div className="flex flex-col mt-3 md:w-1/2 md:mr-2">
                                        <InputText title={t('createParkingArea.city')} value={parkingArea.city} onChange={(value) => { handleCityChange(value) }} />
                                    </div>
                                    <div className="flex flex-col mt-3 md:w-1/2 md:ml-2">
                                        <InputText title={t('createParkingArea.country')} value={parkingArea.country} onChange={(value) => { handleCountryChange(value) }} disabled={true} />
                                    </div>
                                </div>
                            </>
                        </CollapsibleContainer>
                    </div>
                    <div className="flex flex-col w-full mt-6 mb-6">
                        <span className="text-base font-medium pb-1">{t('editParkingArea.description')}</span>
                        <InputTextArea value={parkingArea.description} onChange={(value) => { setParkingArea({ ...parkingArea, description: value }) }} />
                    </div>
                    <div className="flex flex-wrap w-full gap-6 mb-3">
                        {parkingArea && parkingArea.images && parkingArea.images.map((image, i) => {
                            return (
                                <a key={i} href={image} target="_blank" className="mt-4 relative">
                                    <div className="flex h-8 w-8 rounded-full bg-white shadow absolute -top-4 -right-4 items-center justify-center hover:scale-105 transition-all duration-200 ease-in-out cursor-pointer"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        showModal(
                                            t('generic.warning'),
                                            t('generic.areyousure'),
                                            t('generic.yes'),
                                            async () => {
                                                const fileName = image.substring(image.lastIndexOf('/') + 1);
                                                await deleteImage(parkingArea.parkingAreaExtId, fileName);
                                            },
                                            t('generic.no'),
                                            () => { hideModal(); },
                                            'warning');
                                    }}>
                                        <DeleteIcon className="h-5 w-5" />
                                    </div>
                                    <img className="h-32 rounded-xl shadow" src={`${image}?height=128`} />
                                </a>
                            )
                        })}
                        {parkingArea && parkingArea.images && parkingArea.images.length == 0 &&
                            <span className="mb-3">{t('editParkingArea.parkingAreaHasNoImages')}</span>
                        }
                    </div>
                    <div className="flex flex-col w-full mt-3">
                        <span className="font-medium pb-1">{t('editParkingArea.uploadImages')}</span>
                        <InputFileUpload
                            files={images}
                            setFiles={(files) => { setImages([...files]) }}
                            handleFileError={() => {
                                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                            }}
                            isDocuments={false} />
                    </div>
                    <div className="flex w-full mt-6 justify-center">
                        <ButtonText
                            onClick={() => { handleUpdateParkingArea(); }}
                            isLoading={isSaving}
                            className="md:w-1/3 w-full">{t('generic.save')}
                        </ButtonText>
                    </div>
                </div>

            </>
        );
    } else {
        return (
            <div className="fixed inset-0 z-10 bg-white">
                <PageLoad />
            </div>);
    }
};

export default EditParkingAreaPage;