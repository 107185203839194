import Widget from "components/dashboard/widget";
import InputSearch from "components/common/inputSearch";
import { useTranslation } from "react-i18next";
import OrganizationInviteTable from "./organizationInviteTable";

const OrganizationInviteTableWithControls = ({
    searchText,
    setSearchText,
    filteredPortalAdminInvites,
    filteredSublessorInvites,
    setFilteredAdminInvites,
    setFilteredSublessorInvites,
    title,
}) => {
    const { t } = useTranslation();

    if (!filteredPortalAdminInvites && !filteredSublessorInvites) {
        return (null);
    }
    
    return (
        <Widget className="mt-6">
            <div className="flex md:items-center items-start justify-between mb-6 gap-3 flex-col md:flex-row">
                <h1 className="text-xl font-medium">{title}</h1>
                <div className="flex items-center gap-3">
                    <InputSearch
                        placeHolder={t('generic.search')}
                        value={searchText ?? ''}
                        onChange={(value) => { setSearchText(value); }} />
                </div>
            </div>
            <OrganizationInviteTable
                portalAdminInvites={filteredPortalAdminInvites}
                sublessorInvites={filteredSublessorInvites}
                setPortalAdminInvites={setFilteredAdminInvites}
                setSublessorInvites={setFilteredSublessorInvites} />
        </Widget>
    )
}

export default OrganizationInviteTableWithControls;