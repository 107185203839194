import React, { useEffect, useState } from "react";
import { ReactComponent as BackgroundIcon } from 'assets/icons/ico_bg_bell.svg';
import { t } from 'i18next';
import { ReactComponent as ArrowRight } from 'assets/icons/ico_next_green.svg';
import { getOrganizationsForUser } from "api/organizations";
import { getToken } from "context/auth";
import { useNavigate } from "react-router-dom";
import PageLoad from "components/common/pageLoad";
import Alert from "components/common/alert";
import { useMessages } from "context/messages";

const ChooseOrganizationPage = () => {

    const [organizations, setOrganizations] = useState();
    const [loading, setLoading] = useState();
    const navigate = useNavigate();
    const { showToast } = useMessages();

    useEffect(() => {
        const getOrganizations = async () => {

            setLoading(true);

            try {
                const token = await getToken();
                const organizations = await getOrganizationsForUser(token);
                setOrganizations(organizations);
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
            
            setLoading(false);
        }

        getOrganizations();
    }, []);

    return (
        <>
            {!loading &&
                <>
                    <div className="flex flex-col m-auto max-w-md z-20 pt-24">
                        <h1 className="text-2xl font-medium mb-4">{t('chooseOrganizationPage.chooseOrganization')}</h1>
                        <span className="font-base text-base mb-6">{t('chooseOrganizationPage.description')}</span>
                        {organizations && organizations.length > 0 &&
                            <div className="flex flex-col rounded-lg shadow-md pt-2 pb-2">
                                {organizations.map((organization, iteration) => {
                                    return <OrganizationItem key={iteration} organization={organization} navigate={navigate} />
                                })
                                }
                            </div>
                        }
                        {organizations && organizations.length == 0 &&
                            <Alert title={t('chooseOrganizationPage.noOrganizationAvailableTitle')} description={t('chooseOrganizationPage.noOrganizationAvailableDescription')} template='info' />
                        }
                        <button onClick={() => { navigate('/verified/create-organization') }} className="h-11 mt-6 rounded-3xl shadow-secondaryButton text-airpark-green bg-white">{t('chooseOrganizationPage.createNewOrganization')}</button>
                    </div>
                    <BackgroundIcon className="absolute bottom-6 left-0 max-h-[100%] h-[150px] w-[275px] z-10" />
                </>
            }
            {loading &&
                <PageLoad />
            }
        </>
    );
}

const OrganizationItem = ({ organization, navigate }) => {
    return (
        <button onClick={() => { navigate(`/${organization.organizationExtId.toLowerCase()}`) }} className="flex items-center justify-between h-11 border-slate-200 border-b-[1px] last:border-b-0 pl-6 pr-6 cursor-pointer">
            <span className="truncate max-w-[268px]">{organization.name}</span>
            <ArrowRight className="text-green-500" />
        </button>
    );
}

export default ChooseOrganizationPage;