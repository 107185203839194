import React from "react";
import { useTranslation } from "react-i18next";
import { useMessages } from "context/messages";
import { useDashboard } from "context/dashboard";
import { useNavigate } from "react-router-dom";

import { ReactComponent as ArrowRightIcon } from 'assets/icons/ico_arrow_right.svg';

import Button from "components/common/button";
import StepIndicator from "./stepIndicator";

import { createParkingArea } from "api/locations";
import { getToken } from "context/auth";
import InputFileUpload from "components/common/inputFileUpload";
import BackButton from "components/dashboard/backButton";

const DetailsView = ({ parkingArea, setParkingArea }) => {
    const { t } = useTranslation();
    const { showToast, showBetweenPagesMessage, showBetweenPagesLoading, hideBetweenPagesLoading } = useMessages();
    const { organization } = useDashboard();
    const navigate = useNavigate();

    const handleBackButton = () => {
        if (!organization.hasAutomaticParkingAreaApproval) {
            navigate(`/${organization.organizationExtId.toLowerCase()}/create-parking-area/proofofownership`);
        } else {
            navigate(`/${organization.organizationExtId.toLowerCase()}/create-parking-area/parkingspots`);
        }
    }

    const create = async () => {

        showBetweenPagesLoading(t('generic.saving'));

        try {
            const token = await getToken();
            const result = await createParkingArea(token, parkingArea);

            if (!result.error) {
                if (organization.hasAutomaticParkingAreaApproval) {
                    showBetweenPagesMessage(t('createParkingArea.successTitle', { name: parkingArea.name }), t('createParkingArea.successDescription'), () => { navigate(`/${organization.organizationExtId.toLowerCase()}/parking-areas/${result.parkingAreaExtId.toLowerCase()}`); }, 'success');
                } else {
                    showBetweenPagesMessage(t('createParkingArea.inReviewTitle', { name: parkingArea.name }), t('createParkingArea.inReviewDescription'), () => { navigate(`/${organization.organizationExtId.toLowerCase()}/parking-areas/${result.parkingAreaExtId.toLowerCase()}`) }, 'success');
                }
            } else {
                hideBetweenPagesLoading();
                showToast(t('createParkingArea.somethingWentWrongTitle'), t('createParkingArea.somethingWentWrongDescription'), 'error');
            }
        } catch (error) {
            console.error(error);
            showToast(t('createParkingArea.somethingWentWrongTitle'), t('createParkingArea.somethingWentWrongDescription'), 'error');
        }
    }
    return (
        <>
            <BackButton/>
            <div className="flex flex-col m-auto max-w-[768px] w-full">
                <h1 className="mt-14 text-center text-2xl font-medium">{t('createParkingArea.parkingAreaDetailsTitle')}</h1>
                <p className="mt-3 text-left text-base font-base mb-6">{t('createParkingArea.parkingAreaDetailsDescription')}</p>
                <span className="font-medium">{t('createParkingArea.information')}</span>
                <textarea value={parkingArea.description} onChange={(e) => { setParkingArea({ ...parkingArea, description: e.target.value }) }} className="flex w-full bg-airpark-gray-300 rounded-xl p-3 mb-6"></textarea>
                <span className="font-medium">{t('createParkingArea.photo')}</span>
                <InputFileUpload
                    files={parkingArea.parkingAreaImageFiles}
                    setFiles={(files) => { setParkingArea({ ...parkingArea, parkingAreaImageFiles: files }) }}
                    handleFileError={() => { showToast(t('createParkingArea.fileNotValidTitle'), t('createParkingArea.fileNotValidDescription'), 'error'); }} />
                <div className="flex relative justify-center mt-12">
                    <StepIndicator currentStep={organization.hasAutomaticParkingAreaApproval ? 3 : 4} totalNumberOfSteps={organization.hasAutomaticParkingAreaApproval ? 3 : 4} />
                    <div className="flex absolute right-0">
                        <Button onClick={() => {
                            create();
                        }}>
                            <span className="flex opacity-100">
                                <ArrowRightIcon />
                            </span>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DetailsView;