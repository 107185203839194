import React, { useState } from 'react';
import InputSearch from "components/common/inputSearch";
import { useTranslation } from "react-i18next";
import { ReactComponent as FilterIcon } from "assets/icons/ico_filter.svg";
import ButtonText from "components/common/buttonText";

const AdditionalOptions = ({ withSearchInput, children, onSearchChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation();
  
  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='flex flex-col xl:flex-row gap-3 w-full'>
        <div className="hidden xl:flex">
          {withSearchInput && <InputSearch placeHolder={t('generic.search')} value={searchText} onChange={(value) => {setSearchText(value); onSearchChange(value)}} />}
        </div>

        <div className='flex flex-row gap-4 justify-between xl:justify-start xl:hidden'>
            {withSearchInput && <InputSearch placeHolder={t('generic.search')} value={searchText} onChange={(value) => {setSearchText(value); onSearchChange(value)}} />}
           
            <ButtonText onClick={toggleOptions}>
              <FilterIcon className='white'></FilterIcon>
            </ButtonText>
        </div>

      <div
        className={`flex-col xl:flex-row gap-3 ${
          isOpen ? 'flex' : 'hidden'
        } ${isOpen ? 'block' : 'hidden'}`}
      >
        {children}
      </div>

      <div className='hidden xl:flex flex-row gap-4'>           
        <ButtonText onClick={toggleOptions}>
          <FilterIcon className='white'></FilterIcon>
        </ButtonText>
      </div>
    </div>
  );
};

export default AdditionalOptions;
