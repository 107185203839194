import React from "react";
import Tooltip from "../tooltip/tooltip";
import { ReactComponent as IconSearch } from 'assets/icons/ico_search.svg';

const InputSearch = ({ title, value, onChange, autoComplete, placeHolder, disabled, className, tooltip }) => {

    let baseClassName = 'w-full h-11 bg-airpark-gray-300 rounded-xl p-2 pl-9 text-airpark-input-field focus:outline-none';

    if (!autoComplete) {
        autoComplete = 'off';
    }

    if (disabled) {
        baseClassName = `${baseClassName} opacity-50`;
    }

    if (!className) {
        className = baseClassName;
    } else {
        className = `${baseClassName} ${className}`;
    }

    return (
        <div className="flex-1 md:flex-0">
            <div className="flex relative items-center">
                {title &&
                    <span className="text-base font-medium pb-1">{title}</span>
                }
                {tooltip &&
                    <Tooltip tooltip={tooltip} />
                }
            </div>
            <IconSearch className="h-5 w-5 absolute mt-3 ml-2 opacity-50" />
            <input value={value ?? ''} onChange={(e) => { onChange(e.target.value); }} type="search" disabled={disabled} autoComplete={autoComplete} placeholder={placeHolder} className={className}></input>
        </div>
    );
}

export default InputSearch;