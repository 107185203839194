
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import formatDaysToDaySpan from "helpers/formatDaysToDaySpan";

import { useMessages } from "context/messages";
import { getToken } from "context/auth";

import { ReactComponent as PermitLargeIcon } from 'assets/icons/ico_permits_large.svg';

import Alert from 'components/common/alert';
import PermitsTable from "components/pageSpecific/permits/permitsTable";
import Widget from "components/dashboard/widget";
import ButtonText from "components/common/buttonText";
import WidgetLoadingSkeleton from "components/dashboard/widgetLoadingSkeleton";

import { getPermitsForParkingArea, getPermitStatisticsForParkingArea } from "api/permits";
import DropDownMultiSelector from "components/common/dropDownMultiSelector";
import VideoInstructions from "components/common/videoInstructions";
import AdditionalOptions from "./additionalOptions";

const PermitsPage = () => {
  
    const { t } = useTranslation();
    const { showToast } = useMessages();
    const [permits, setPermits] = useState();
    const [isLoading, setIsLoading] = useState();
    const { organizationExtId, parkingAreaExtId } = useParams();
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState();
    const [filteredPermits, setFilteredPermits] = useState();
    const [filterPermitOptions, setFilterPermitOptions] = useState([
        { title: t('permitsPage.expired'), value: 'expired', isChecked: false },
        { title: t('permitsPage.unpaid'), value: 'unpaid', isChecked: false },]);
    const [statistics, setStatistics] = useState();
    const [noFilterResults, setNoFilterResults] = useState(false);
    const [csvColumns, setCsvColumns] = useState([
        { title: t('permitTable.phoneNumber'), key: 'phone', isChecked: true, isDisabled: true },
        { title: t('permitTable.spotIdentifier'), key: 'spotIdentifier', isChecked: true },
        { title: t('permitTable.status'), key: 'status', isChecked: true },
        { title: t('permitTable.email'), key: 'email', isChecked: true },
        { title: t('permitTable.firstName'), key: 'firstName', isChecked: false },
        { title: t('permitTable.lastName'), key: 'lastName', isChecked: false },
        { title: t('permitTable.description'), key: 'description', isChecked: true },
        { title: t('permitTable.licensePlateNumber'), key: 'licensePlateNumber', isChecked: false },
        { title: t('permitTable.period'), key: 'period', isChecked: true },
        { title: t('permitTable.days'), key: 'days', isChecked: false },
        { title: t('permitTable.time'), key: 'time', isChecked: false },
        { title: t('permitTable.subletting'), key: 'subletting', isChecked: false },
        { title: t('permitTable.invoicedBy'), key: 'invoicedBy', isChecked: false },
        { title: t('permitTable.price'), key: 'friendlyPrice', isChecked: false },
    ]);

    useEffect(() => {
        const userSelectedCsvColumns = document.cookie.split('; ').find(row => row.startsWith('selectedPermitColumns'));

        if (userSelectedCsvColumns) {
            setCsvColumns(JSON.parse(userSelectedCsvColumns.split('=')[1]))
        }
    }, [])

    useEffect(() => {
        document.cookie = `selectedPermitColumns=${JSON.stringify(csvColumns)}`;
    }, [csvColumns])

    useEffect(() => {
        const load = async () => {
            setIsLoading(true);

            try {
                const token = await getToken();
                const result = await getPermitsForParkingArea(token, parkingAreaExtId);
                const statisticsResult = await getPermitStatisticsForParkingArea(token, parkingAreaExtId);

                if (!statisticsResult.error) {
                    setStatistics(statisticsResult);
                } else {
                    console.error(statisticsResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                if (!result.error) {
                    setPermits(result);
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setIsLoading(false);
        }

        load();
    }, [parkingAreaExtId]);

    useEffect(() => {
        if (permits) {
            let shouldFilter = false;

            filterPermitOptions.forEach((option) => {
                if (option.isChecked) {
                    shouldFilter = true;
                }
            });

            let filteredPermits = [...permits];

            if (!shouldFilter) {
                /* Exclude expired permits by default */
                filteredPermits = [...permits.filter((permit) => {
                    return permit.status !== 'expired';
                })];
            }

            if (shouldFilter) {
                filteredPermits = [...filteredPermits.filter((permit) => {
                    if (filterPermitOptions[0].isChecked && permit.status === 'expired') {
                        return true;
                    }

                    if (filterPermitOptions[1].isChecked && permit.paymentStatus.toLowerCase() !== 'paid') {
                        return true;
                    }
                })];
            }

            if (searchText) {
                filteredPermits = [...filteredPermits.filter((permit) => {
                    if (permit.description?.toLowerCase().includes(searchText.toLowerCase())) {
                        return true;
                    }

                    if (permit.spotIdentifier?.toLowerCase().includes(searchText.toLowerCase())) {
                        return true;
                    }

                    if (permit.firstName?.toLowerCase().includes(searchText.toLowerCase())) {
                        return true;
                    }

                    if (permit.lastName?.toLowerCase().includes(searchText.toLowerCase())) {
                        return true;
                    }

                    if (permit.phone?.toLowerCase().includes(searchText.toLowerCase())) {
                        return true;
                    }

                    if (permit.registrationIdentifier?.toLowerCase().includes(searchText.toLowerCase())) {
                        return true;
                    }
                })];
            }

            setNoFilterResults(filteredPermits?.length === 0 && permits?.length > 0);
            setFilteredPermits(filteredPermits);
        }
    }, [filterPermitOptions, searchText, permits]);

    const handleFilterPermits = (option) => {
        const newOptions = [...filterPermitOptions];
        const optionIndex = newOptions.findIndex((opt) => opt.value === option.value);
        newOptions[optionIndex].isChecked = !newOptions[optionIndex].isChecked;
        setFilterPermitOptions(newOptions);
    }

    const toggleCsvColumn = (columnKey) => {
        setCsvColumns((prevCols) =>
            prevCols.map((col) =>
                col.key === columnKey ? { ...col, isChecked: !col.isChecked } : col
            )
        );
    }

    const escapeCSVValue = (value) => {
        if (typeof value === 'string' && value.includes(',')) {
            return `"${value.replace(/"/g, '""')}"`;
        }
        return value;
    };

    const handleDownloadCSV = () => {
        const filteredColumns = csvColumns.filter(col => col.isChecked);
        let csvContent = filteredColumns.map(col => col.title).join(",") + "\n";
    
        filteredPermits.forEach((permit) => {
            const rowData = filteredColumns.map((col) => {
                switch (col.key) {
                    case 'friendlyPrice': {
                        const { isPaymentHandledManually, friendlyPrice, unit, friendlyPeriod } = permit;
                        if (!isPaymentHandledManually && friendlyPrice && unit) {
                            return `${friendlyPrice}/${t(`priceUnit.${unit}`)}`;
                        }
                        if (!friendlyPeriod && unit) {
                            return '-';
                        }
                        return '';
                    }
    
                    case 'licensePlateNumber':
                        return permit.registrationIdentifier;
    
                    case 'period':
                        return permit.endDateTime
                            ? permit.friendlyPeriod
                            : `${permit.friendlyPeriod} - ${t('permitTable.ongoing')}`;
    
                    case 'subletting': {
                        return permit.isSublettingAllowed
                            ? `${t('permitTable.allowed')} (${t(
                                permit.isCommunity ? 'permitTable.community' : 'permitTable.everyone'
                            ).toLowerCase()})`
                            : t('permitTable.notallowed');
                    }
    
                    case 'days': {
                        const value = permit.isRecurring
                            ? formatDaysToDaySpan(permit, t)
                            : t('parkingArea.asDefinedByPeriod');
                        return escapeCSVValue(value);
                    }
    
                    case 'time': {
                        const value = permit.isRecurring
                            ? permit.friendlyTime
                            : t('parkingArea.asDefinedByPeriod');
                        return escapeCSVValue(value);
                    }
    
                    case 'invoicedBy': {
                        const value = permit.isPaymentHandledManually
                            ? t('permitTable.thirdParty')
                            : t('generic.airPark');
                        return escapeCSVValue(value);
                    }
    
                    case 'phone':
                        return permit.phone ? `="${permit.phone}"` : '-';
                    
                    case 'status':
                      return t(`permitTable.${permit.status}`);
    
                    default:
                        return escapeCSVValue(permit[col.key]) || '-';
                }
            });
    
            csvContent += rowData.join(",") + "\n";
        });

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");

        link.setAttribute("href", URL.createObjectURL(blob));
        link.setAttribute("download", filteredPermits?.length > 0 ?
            `${filteredPermits[0].parkingAreaName} ${t('permitParkingSpotsTable.permits').toLowerCase()}` :
            t('permitParkingSpotsTable.permits'));
        link.click();
    };

    const tableOptions = () => (
        <>
            <DropDownMultiSelector
                wFull
                minWidth="223px"
                options={filterPermitOptions}
                onOptionSelected={(option) => { handleFilterPermits(option); }}
                />

            <DropDownMultiSelector
                options={csvColumns}
                onOptionSelected={option => toggleCsvColumn(option.key)}
                label={t('permitsPage.selectColumns')}
                >
            </DropDownMultiSelector>
        </>
    ) 

    return (
      <>
        <Widget>
          <div className="flex md:flex-row flex-col-reverse gap-12 justify-between items-center">
            <div className="flex flex-col">
              <h2 className="text-xl font-medium mb-3">
                {t("permitsPage.permitsPageTitle")}
              </h2>
              <span>{t("permitsPage.permitsPageDescription")}</span>
            </div>
            <span className="flex w-20 h-20">
              <PermitLargeIcon />
            </span>
          </div>
        </Widget>
        {isLoading &&
          <div className="mt-6">
            <WidgetLoadingSkeleton />
          </div>
        }
        {!isLoading && (
          <>
            <div className="flex flex-col md:flex-row mt-6 gap-6">
              <Widget className="md:w-1/3">
                <h2 className="text-center">
                  {t("permitsPage.activePermits")}
                </h2>
                <span className="text-center text-xl font-medium mt-3">
                  {statistics?.numberOfActivePermits ?? "0"}
                </span>
              </Widget>
              <Widget className="md:w-1/3">
                <h2 className="text-center">
                  {t("permitsPage.parkingSpotsWithoutPermits")}
                </h2>
                <span className="text-center text-xl font-medium mt-3">
                  {statistics?.numberOfAvailableParkingSpots ?? "0"}
                </span>
              </Widget>
              <Widget className="md:w-1/3">
                <h2 className="text-center">
                  {t("permitsPage.permitsInMarketplace")}
                </h2>
                <span className="text-center text-xl font-medium mt-3">
                  {statistics?.numberOfPermitsInMarketplace ?? "0"}
                </span>
              </Widget>
            </div>
            <div className="mt-6">
              <VideoInstructions
                title={t("generic.videoInstructions")}
                description={t("permitsPage.videoInstructionsDescription")}
                videoUrl="https://www.youtube.com/watch?v=BUv7FOlfYvk"
              />
            </div>
            <div className="md:block mt-6">
              <Alert
                title={t("payoutDetailsPage.openCsvTitle")}
                description={t("payoutDetailsPage.openCsvDescription")}
                template="info"
                expandable
              />
            </div>

            <Widget className="mt-6">
              <div className="flex gap-3 md:flex-row flex-col lg:items-center justify-between mb-6">
                <h2 className="text-xl font-medium">
                  {t("permitsPage.addPermitTitle")}
                </h2>
                <div className="flex flex-col md:max-2xl:flex-col-reverse 2xl:flex-row gap-3 items-stretch md:items-end">
                  <div className="flex flex-row flex-wrap md:flex-row gap-3">
                    <AdditionalOptions
                      withSearchInput={true}
                      onSearchChange={(text) => setSearchText(text)}
                    >
                      {tableOptions()}
                    </AdditionalOptions>
                  </div>
                  <div className="flex flex-col md:flex-row gap-3">
                  <ButtonText onClick={handleDownloadCSV}>
                    {t("permitsPage.downloadCSV")}
                  </ButtonText>
                  <ButtonText
                    onClick={() => {
                      navigate(
                        `/${organizationExtId.toLowerCase()}/parking-areas/${parkingAreaExtId}/create-permit`
                      );
                    }}
                  >
                    {t("permitsPage.createPermit")}
                  </ButtonText>
                  </div>

                </div>
              </div>
              <PermitsTable
                permits={filteredPermits}
                setPermits={setFilteredPermits}
                isMarketplace={false}
                noResult={noFilterResults}
              />
            </Widget>
          </>
        )}
      </>
    );
}

export default PermitsPage;