import { useEffect, useState } from "react";
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/ico_arrow_left.svg';
import ButtonText from "components/common/buttonText";
import WidgetLoadingSkeleton from "components/dashboard/widgetLoadingSkeleton";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import InputTextArea from "components/common/inputTextArea";
import { getParkingArea } from "api/locations";
import { getToken } from "context/auth";
import { getAirPass } from "api/airPass";
import ParkingSpotMap from "components/pageSpecific/parkingArea/parkingSpotMap";
import { updateAirPass } from "api/airPass";
import { useMessages } from "context/messages";
import { useDashboard } from "context/dashboard";
import BackButton from "components/dashboard/backButton";

const EditAirPassPage = () => {

    const [airPass, setAirPass] = useState();
    const [parkingArea, setParkingArea] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { airPassExtId, parkingAreaExtId } = useParams();
    const { showToast, showBetweenPagesMessage } = useMessages();
    const { organization } = useDashboard();

    useEffect(() => {
        const load = async () => {
            try {
                const token = await getToken();
                const parkingAreaResult = await getParkingArea(token, parkingAreaExtId);
                const airPassResult = await getAirPass(token, airPassExtId);

                if (parkingAreaResult.error) {
                    console.error(parkingAreaResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                } else {
                    setParkingArea(parkingAreaResult);
                }

                if (airPassResult.error) {
                    console.error(airPassResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                } else {
                    setAirPass(airPassResult);
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            } finally {
                setIsLoading(false);
            }
        }

        load();
    }, []);

    const handleSave = async () => {
        try {
            setIsSaving(true);
            const token = await getToken();
            const result = await updateAirPass(token, airPass);

            if (result.error) {
                console.error(result);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            } else {
                showBetweenPagesMessage(
                    t('editAirPassPage.updatedSuccessTitle'),
                    t('editAirPassPage.updatedSuccessDescription'),
                    () => { navigate(`/${organization.organizationExtId.toLowerCase()}/parking-areas/${parkingAreaExtId.toLowerCase()}/airpass`); },
                    'success');
            }
        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        } finally {
            setIsSaving(false);
        }
    }

    return (
        <>
            <BackButton />
            <div className="flex flex-col m-auto max-w-[448px] w-full">
                {!isLoading && airPass && parkingArea &&
                    <>
                        <h1 className="mt-14 text-center text-2xl font-medium mb-12">{t('editAirPassPage.title')}</h1>
                        <div className="flex flex-col gap-y-3">
                            <InputTextArea
                                title={t('editAirPassPage.airPassDescriptionTitle')}
                                placeHolder={t('editAirPassPage.airPassDescriptionPlaceholder')}
                                value={airPass.description}
                                onChange={(value) => {
                                    setAirPass({ ...airPass, description: value })
                                }}
                            />
                            <span
                                className="text-base font-medium pb-1 mt-3">{t('editAirPassPage.editAirPassPosition')}</span>
                            <ParkingSpotMap
                                parkingArea={parkingArea}
                                parkingSpot={airPass}
                                setParkingSpot={setAirPass}
                                disableRemoveMarkerButton={true} />
                        </div>
                        <ButtonText
                            className="mt-6"
                            isLoading={isSaving}
                            disabled={isSaving}
                            onClick={() => {
                                handleSave();
                            }}>
                            {t('generic.update')}
                        </ButtonText>
                    </>
                }
                {isLoading &&
                    <WidgetLoadingSkeleton />
                }
            </div>
        </>
    );
};

export default EditAirPassPage;