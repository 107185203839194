import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { getParkingArea, getParkingSpots, updateParkingArea } from "api/locations";
import { getPriceRuleSetsForParkingArea } from "api/priceRuleSets";

import { getToken } from "context/auth";
import { useMessages } from "context/messages";
import { useDashboard } from "context/dashboard";

import ButtonText from "components/common/buttonText";
import WidgetLoadingSkeleton from "components/dashboard/widgetLoadingSkeleton";
import Alert from "components/common/alert";
import DropdownSelector from "components/common/dropdownSelector/dropdownSelector";
import ParkingSpotsTable from "components/pageSpecific/parkingArea/parkingSpotsTable";
import PriceRuleSetsTable from "components/pageSpecific/parkingArea/priceRuleSetsTable";
import AddParkingSpotsModal from "components/pageSpecific/parkingArea/addParkingSpotsModal";
import DropDownMultiSelector from "components/common/dropDownMultiSelector";
import EditParkingSpotModal from "components/pageSpecific/parkingArea/editParkingSpotModal";
import ParkingAreaMap from "components/dashboard/parkingAreaMap";
import Widget from "components/dashboard/widget";
import VideoInstructions from "components/common/videoInstructions";

const ParkingAreaPage = () => {
    const { showToast } = useMessages();
    const { organization } = useDashboard();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { parkingAreaExtId } = useParams();

    const [parkingArea, setParkingArea] = useState();
    const [parkingSpots, setParkingSpots] = useState();
    const [selectedParkingSpots, setSelectedParkingSpots] = useState();
    const [priceRuleSets, setPriceRuleSets] = useState();
    const [displayAddParkingSpotsModal, setDisplayAddParkingSpotsModal] = useState();
    const [loading, setLoading] = useState(true);
    const [filterParkingSpotsOptions, setFilterParkingSpotOptions] = useState([
        { title: t('parkingArea.locationSpecific'), value: 'locationspecific', isChecked: false },
        { title: t('parkingArea.outdoors'), value: 'outdoor', isChecked: false },
        { title: t('parkingArea.indoors'), value: 'indoor', isChecked: false },
        { title: t('parkingArea.fenced'), value: 'fenced', isChecked: false },
        { title: t('parkingArea.hasChargingPost'), value: 'haschargingpost', isChecked: false }]);
    const [showEditParkingSpotModal, setShowEditParkingSpotModal] = useState();
    const [parkingSpotToEdit, setParkingSpotToEdit] = useState();

    useEffect(() => {
        if (parkingArea && priceRuleSets && parkingArea) {
            setLoading(false);
        }
    }, [parkingArea, priceRuleSets, parkingSpots]);

    useEffect(() => {
        const load = async () => {
            try {
                const token = await getToken();
                const parkingAreaResult = await getParkingArea(token, parkingAreaExtId);

                if (parkingAreaResult.error) {
                    console.error(parkingAreaResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                } else {
                    setParkingArea(parkingAreaResult);
                }

                const parkingSpotsResult = await getParkingSpots(token, parkingAreaExtId);

                if (parkingSpotsResult && !parkingSpotsResult.error) {
                    setParkingSpots(parkingSpotsResult);
                    setSelectedParkingSpots(parkingSpotsResult);
                } else {
                    console.error(parkingSpotsResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                const priceRuleSetsResult = await getPriceRuleSetsForParkingArea(token, parkingAreaExtId);

                if (priceRuleSetsResult.error) {
                    console.error(priceRuleSetsResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                } else {
                    setPriceRuleSets(priceRuleSetsResult);
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        }

        load();
    }, []);

    useEffect(() => {
        if (filterParkingSpotsOptions && parkingSpots) {

            let filteredParkingSpots = parkingSpots;
            let shouldFilter = false;

            filterParkingSpotsOptions.forEach((option) => {
                if (option.isChecked) {
                    shouldFilter = true;
                }
            });

            if (!shouldFilter) {
                setSelectedParkingSpots([...parkingSpots]);
                return;
            }

            filterParkingSpotsOptions.forEach((option) => {
                /* Filter on any criterion that is checked */
                if (option.isChecked) {
                    switch (option.value) {
                        case 'indoor':
                            filteredParkingSpots = filteredParkingSpots.filter((parkingSpot) => { return parkingSpot.isIndoor === true });
                            break;
                        case 'outdoor':
                            filteredParkingSpots = filteredParkingSpots.filter((parkingSpot) => { return parkingSpot.isIndoor !== true });
                            break;
                        case 'haschargingpost':
                            filteredParkingSpots = filteredParkingSpots.filter((parkingSpot) => { return parkingSpot.hasChargingPost === true });
                            break;
                        case 'fenced':
                            filteredParkingSpots = filteredParkingSpots.filter((parkingSpot) => { return parkingSpot.isFenced === true });
                            break;
                        case 'locationspecific':
                            filteredParkingSpots = filteredParkingSpots.filter((parkingSpot) => { return parkingSpot.isNumbered === true });
                            break;
                    }
                }
            });

            setSelectedParkingSpots([...filteredParkingSpots]);
        }

    }, [filterParkingSpotsOptions, parkingSpots]);

    const handleParkingAreaIsFreeFallbackChange = (value) => {

        const update = async (parkingArea) => {
            try {
                const token = await getToken();
                var result = await updateParkingArea(token, parkingArea);

                if (result.error) {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                } else {
                    setParkingArea(result);
                    showToast(t('parkingArea.updatedTitle'), t('parkingArea.updatedDescription'), 'success');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        }

        parkingArea.isFreeParkingAllowedAsFallback = value === 'true';
        update(parkingArea);
    };

    const handleFilterParkingSpots = (option) => {
        const index = filterParkingSpotsOptions.findIndex(({ value }) => value === option.value);
        filterParkingSpotsOptions[index] = { ...option, isChecked: !option.isChecked };
        setFilterParkingSpotOptions([...filterParkingSpotsOptions]);
    }

    const updateParkingSpotInLists = (parkingSpotToUpdate) => {
        const parkingSpotsIndex = parkingSpots.findIndex(({ parkingSpotExtId }) => parkingSpotExtId == parkingSpotToUpdate.parkingSpotExtId);
        const selectedParkingSpotsIndex = parkingSpots.findIndex(({ parkingSpotExtId }) => parkingSpotExtId == parkingSpotToUpdate.parkingSpotExtId);

        parkingSpots[parkingSpotsIndex] = parkingSpotToUpdate;
        selectedParkingSpots[selectedParkingSpotsIndex] = parkingSpotToUpdate;

        setParkingSpots([...parkingSpots]);
        setSelectedParkingSpots([...selectedParkingSpots]);
    }

    return (
        <>
            {loading &&
                <WidgetLoadingSkeleton />
            }

            {!loading && parkingArea &&
                <>
                    <div className="flex mb-6">
                        {parkingArea.parkingAreaStatus == 'Approved' &&
                            <Alert title={t('parkingArea.approvedTitle', { name: parkingArea.name })} description={t('parkingArea.approvedDescription', { name: parkingArea.name })} template='success' />
                        }
                        {parkingArea.parkingAreaStatus == 'Pending' &&
                            <Alert title={t('parkingArea.awaitingApprovalTitle', { name: parkingArea.name })} description={t('parkingArea.awaitingApprovalDescription', { name: parkingArea.name })} template='warning' />
                        }
                    </div>
                    <Widget>
                        <div className="flex flex-row justify-between items-center">
                            <h2 className="text-xl font-medium">{parkingArea.name}</h2>
                            <ButtonText isSecondary={true} onClick={() => { navigate('edit'); }}>{t('parkingArea.edit')}</ButtonText>
                        </div>
                        <div className="flex flex-col md:flex-row mt-4">
                            <div className="flex flex-col md:w-1/2 md:mr-16">
                                <div className="flex flex-col">
                                    <span className="font-medium">{t('parkingArea.description')}</span>
                                    {parkingArea.description &&
                                        <span>{parkingArea.description}</span>
                                    }
                                    {!parkingArea.description &&
                                        <i>{t('parkingArea.noDescription', { name: parkingArea.name })}</i>
                                    }
                                    {parkingArea.images &&
                                        <div className="flex flex-wrap gap-3">
                                            {parkingArea.images.map((image, i) => {
                                                return (
                                                    <img key={i} className="h-20 mt-4 rounded-lg" src={`${image}?height=80`} />
                                                )
                                            })
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="flex mt-6 md:mt-0 md:w-1/2">
                                <ParkingAreaMap
                                    parkingArea={parkingArea}
                                    isRoadmapDefault={false}
                                    isSearchDisabled={true} />
                            </div>
                        </div>
                    </Widget>
                </>
            }

            {!loading && priceRuleSets &&
                <>
                    <div className="mt-6">
                        <VideoInstructions
                            title={t('generic.videoInstructions')}
                            description={t('parkingArea.videoInstructionsDescription')}
                            videoUrl='https://www.youtube.com/watch?v=Scsghb4cSCM' />
                    </div>
                    <Widget className="mt-6">
                        <div className="flex flex-row justify-between items-center mb-6">
                            <h2 className="text-xl font-medium">{t('parkingArea.availability')}</h2>
                            <ButtonText
                                onClick={() => { navigate(`/${organization.organizationExtId.toLowerCase()}/parking-areas/${parkingArea.parkingAreaExtId.toLowerCase()}/add-price-and-availability`) }}>
                                {t('parkingArea.add')}
                            </ButtonText>
                        </div>
                        <PriceRuleSetsTable priceRuleSets={priceRuleSets} setPriceRuleSets={setPriceRuleSets} />
                        <div className="flex flex-col sm:flex-row items-left justify-between mt-3">
                            <span>
                                {t('parkingArea.outsideAvailabilityHours')}
                            </span>
                            <div className="flex max-w-xs w-full">
                                <DropdownSelector
                                    defaultValue={parkingArea.isFreeParkingAllowedAsFallback}
                                    onChange={(value) => { handleParkingAreaIsFreeFallbackChange(value); }}
                                    options={[{ title: t('parkingArea.parkingNotAllowed'), value: false }, { title: t('parkingArea.parkingFree'), value: true }]} />
                            </div>
                        </div>
                    </Widget>
                </>
            }

            {!loading && parkingSpots &&
                <Widget className="mt-6">
                    <div className="flex flex-col md:flex-row justify-between flex-start md:items-center mb-6 gap-3">
                        <h2 className="text-xl font-medium">{t('parkingArea.parkings')}</h2>
                        <div className="flex-column sm:flex items-center">
                            <div className="md:flex mb-3 sm:mb-0">
                                <DropDownMultiSelector
                                    options={filterParkingSpotsOptions}
                                    onOptionSelected={(option) => { handleFilterParkingSpots(option); }}
                                    />
                            </div>
                            <ButtonText
                                onClick={() => { setDisplayAddParkingSpotsModal(!displayAddParkingSpotsModal); }}
                                className="ml-3">{t('parkingArea.add')}
                            </ButtonText>
                        </div>
                        {displayAddParkingSpotsModal &&
                            <AddParkingSpotsModal
                                display={displayAddParkingSpotsModal}
                                setDisplay={setDisplayAddParkingSpotsModal}
                                setParkingSpots={setParkingSpots}
                                setSelectedParkingSpots={setSelectedParkingSpots} />
                        }
                    </div>
                    <ParkingSpotsTable
                        parkingSpots={selectedParkingSpots}
                        setParkingSpots={setSelectedParkingSpots}
                        setParkingSpotToEdit={setParkingSpotToEdit}
                        setShowEditParkingSpotModal={setShowEditParkingSpotModal} />
                    <EditParkingSpotModal
                        updateParkingSpotInLists={updateParkingSpotInLists}
                        parkingSpotToEdit={parkingSpotToEdit}
                        setParkingSpotToEdit={setParkingSpotToEdit}
                        display={showEditParkingSpotModal}
                        setDisplay={setShowEditParkingSpotModal}
                        parkingArea={parkingArea} />
                </Widget>
            }
        </>

    );
}

export default ParkingAreaPage;