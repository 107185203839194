import React, { useEffect } from 'react';
import { ReactComponent as IconClose } from 'assets/icons/ico_close.svg';

const Modal = ({ setDisplay, display, title, onClose, children, overlayClassName, modalClassName, innerClassName }) => {

    const handleOverlayOnClick = (e) => {
        document.body.classList.remove('overflow-hidden');
        setDisplay(false);
        e.stopPropagation();
    }

    useEffect(() => {
        if (display) {
            document.body.classList.add('overflow-hidden');
        } else {
            if (onClose) {
                onClose();
            }
            document.body.classList.remove('overflow-hidden');
        }
    }, [display]);

    const classNames = (list) => {
        return list.filter(Boolean).join(" ");
    }

    if (display) {
        return (
            <>
                {/* Overlay */}
                <div className={"fixed inset-0 z-50 bg-black bg-opacity-60"}>
                </div>
                {/* Modal */}
                <div onMouseDown={(e) => handleOverlayOnClick(e)} className={classNames(["flex fixed inset-0 z-50 overflow-y-auto overflow-x-hidden", overlayClassName])}>
                    <div onMouseDown={(e) => e.stopPropagation()} className={classNames(["flex m-auto justify-center items-center", modalClassName])}>
                        <div className={classNames(["w-[375px] bg-white rounded-lg p-6", innerClassName])}>
                            <div className="flex justify-end">
                                <button className="w-[16px] h-[16px]" onClick={() => { setDisplay(false) }}>
                                    <IconClose />
                                </button>
                            </div>
                            <h2 className='text-xl font-medium pb-2'>{title}</h2>
                            {children}
                        </div>
                    </div>
                </div>
            </>)
    } else {
        return (null);
    }
}

export default Modal;