import React from "react";
import { format, parse } from 'date-fns';

const InputDate = ({title, value, onChange, className}) => {

    const baseClassName = 'w-full md:min-w-[320px] min-w-full border-airpark-gray-300 hover:border-airpark-gray-400 border-2 h-11 rounded-xl pl-2 pr-2 text-airpark-green font-medium text-center focus:outline-none';

    if (!className) {
        className = baseClassName;
    } else {
        className = `${baseClassName} ${className}`;
    }

    const handleDateChange = (date) => {
        if (!date) {
            return;
        }
        const updatedDateString = `${date} 00:00`;
        const updatedDate = parse(updatedDateString, 'yyyy-MM-dd HH:mm', new Date());
        onChange(updatedDate);
    }

    return (
        <div className="w-full md:w-max">
            <span className="font-medium pb-1">{title}</span>
            <div className="flex flex-row">
                <input value={format(value, 'yyyy-MM-dd')} onChange={(e) => { handleDateChange(e.target.value); }} type="date" className={className} />
            </div>
        </div>
    );
}

export default InputDate;