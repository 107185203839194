import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ButtonText from 'components/common/buttonText';

import { ReactComponent as SuccessIcon } from 'assets/icons/ico_success.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/ico_info_circle_blue.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/ico_warning_yellow.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/ico_error.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/ico_close.svg';

import { useMessages } from 'context/messages';

const Modal = () => {

    const location = useLocation();
    const { modal, hideModal } = useMessages();

    useEffect(() => {
        hideModal();
    }, [location]);

    useEffect(() => {
        if (modal.display) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [modal.display]);

    if (!modal.display) {
        return (null);
    }

    return (
        <div className='flex fixed inset-0 z-10 bg-black bg-opacity-60'>
            <div onClick={(e) => { hideModal(); }} className="flex p-6 flex-col absolute h-full w-full items-center justify-center z-20">
                <div onClick={(e) => { e.stopPropagation(); }} className="flex flex-col relative justify-center items-center max-w-sm w-full bg-white p-6 rounded-xl">
                    <button onClick={(e) => { e.preventDefault(); hideModal(); }} className="flex absolute top-4 right-4">
                        <CloseIcon className="h-5 w-5" />
                    </button>
                    {modal.template == 'success' &&
                        <SuccessIcon className='w-10 h-10' />
                    }
                    {modal.template == 'info' &&
                        <InfoIcon className='w-10 h-10' />
                    }
                    {modal.template == 'warning' &&
                        <WarningIcon className='w-10 h-10' />
                    }
                    {modal.template == 'error' &&
                        <ErrorIcon className='w-10 h-10' />
                    }
                    {modal.template != 'loading' &&
                        <>
                            <div className="flex flex-col mt-4 justify-center">
                                <h2 className="text-2xl mt-4 text-center">{modal.title}</h2>
                                <p className="mt-2 text-center">{modal.description}</p>
                                {modal.customRender ? modal.customRender() : null}
                            </div>
                            {modal.primaryButtonTitle && modal.primaryButtonOnClick && !modal.secondaryButtonTitle && !modal.secondaryButtonOnClick &&
                                <div className="flex w-full justify-end">
                                    <ButtonText onClick={() => { modal.secondaryButtonOnClick(); }} className='w-full'>
                                        {modal.primaryButtonTitle}
                                    </ButtonText>
                                </div>
                            }
                            {modal.primaryButtonTitle && modal.primaryButtonOnClick && modal.secondaryButtonTitle && modal.secondaryButtonOnClick &&
                                <div className="flex w-full justify-between mt-4">
                                    <ButtonText onClick={() => { hideModal(); }} isSecondary={true} className='w-full'>
                                        {modal.secondaryButtonTitle}
                                    </ButtonText>
                                    <ButtonText onClick={() => { modal.primaryButtonOnClick(); }} className='w-full ml-3' isLoading={modal.loading}>
                                        {modal.primaryButtonTitle}
                                    </ButtonText>
                                </div>
                            }

                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default Modal;