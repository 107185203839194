import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMessages } from "context/messages";

import { ReactComponent as ArrowRightIcon } from 'assets/icons/ico_arrow_right.svg';

import Button from "components/common/button";
import StepIndicator from "./stepIndicator";
import { useDashboard } from "context/dashboard";
import { useNavigate } from "react-router-dom";
import InputFileUpload from "components/common/inputFileUpload";
import BackButton from "components/dashboard/backButton";

const UploadProofOfOwnershipView = ({ parkingArea, setParkingArea }) => {
    const { t } = useTranslation();
    const [isFormValid, setIsFormValid] = useState(true);
    const { showToast } = useMessages();
    const { organization } = useDashboard();
    const navigate = useNavigate();

    useEffect(() => {
        if (parkingArea.proofOfOwnershipFiles && parkingArea.proofOfOwnershipFiles.length > 0) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }

    }, [parkingArea.proofOfOwnershipFiles]);

    return (
        <>  
            <BackButton/>
            <div className="flex flex-col m-auto max-w-[768px] w-full">
                <h1 className="mt-14 text-center text-2xl font-medium">{t('createParkingArea.confirm')}</h1>
                <p className="mt-3 text-left text-base font-base mb-12">{t('createParkingArea.confirmDescription')}</p>
                <InputFileUpload
                    files={parkingArea.proofOfOwnershipFiles}
                    setFiles={(files) => { setParkingArea({ ...parkingArea, proofOfOwnershipFiles: [...files] }) }}
                    handleFileError={() => { showToast(t('createParkingArea.fileNotValidTitle'), t('createParkingArea.fileNotValidDescription'), 'error'); }}
                    isDocuments={true} />
                <div className="flex relative justify-center mt-12">
                    <StepIndicator currentStep={3} totalNumberOfSteps={organization.hasAutomaticParkingAreaApproval ? 3 : 4} />
                    <div className="flex absolute right-0">
                        <Button disabled={!isFormValid} onClick={() => {
                            if (isFormValid) {
                                navigate(`/${organization.organizationExtId.toLowerCase()}/create-parking-area/details`);
                            } else {
                                showToast(t('generic.missingFieldsTitle'), t('generic.missingFieldsDescription'), 'error')
                            }
                        }}>
                            {isFormValid &&
                                <span className="flex opacity-100">
                                    <ArrowRightIcon />
                                </span>
                            }
                            {!isFormValid &&
                                <span className="flex opacity-50">
                                    <ArrowRightIcon />
                                </span>
                            }
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UploadProofOfOwnershipView;