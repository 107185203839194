import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/ico_close.svg';
import { ReactComponent as SunIcon } from 'assets/icons/ico_sun.svg';
import { ReactComponent as GarageIcon } from 'assets/icons/ico_garage_black.svg';
import { ReactComponent as ElectricityIconBlack } from 'assets/icons/ico_electricity_black.svg';
import { ReactComponent as ElectricityIconWhite } from 'assets/icons/ico_electricity_white.svg';
import { ReactComponent as FenceIconBlack } from 'assets/icons/ico_fence_black.svg';
import { ReactComponent as FenceIconWhite } from 'assets/icons/ico_fence_white.svg';
import ButtonText from 'components/common/buttonText';
import RadioButton from 'components/common/radioButton/radioButton';
import { t } from 'i18next';
import ButtonToggle from 'components/common/buttonToggle';
import InputNumber from 'components/common/inputNumber';
import ToggleText from 'components/common/toggleText';
import CollapsibleContainer from 'components/common/collapsibleContainer';
import ButtonSlider from "../../common/buttonSlider";

const AddParkingSpotsModal = ({ setDisplay, display, parkingArea, setParkingArea }) => {

    const handleOverlayOnClick = (e) => {
        setDisplay(false);
        e.stopPropagation();
    }

    const parkingGroupInitialState = { isIndoor: false, numberOfParkingSpots: 1, isChargingPost: false, isFenced: false, isNumbered: false, numberSeriesStart: 1 };
    const [parkingGroup, setParkingGroup] = useState(parkingGroupInitialState);
    const [showFloor, setShowFloor] = useState(false);

    useEffect(() => {
        if (!display) {
            setParkingGroup(parkingGroupInitialState);
        }
    }, [display]);

    useEffect(() => {

        if (parkingGroup.isNumbered) {
            if (Number.isInteger(parkingGroup.numberSeriesStart) && parkingGroup.numberOfParkingSpots) {
                setParkingGroup({ ...parkingGroup, numberSeriesEnd: parkingGroup.numberSeriesStart + parkingGroup.numberOfParkingSpots - 1 })
            } else {
                setParkingGroup({ ...parkingGroup, numberSeriesEnd: '' })
            }
        } else {
            setParkingGroup({ ...parkingGroup, numberSeriesEnd: '', numberSeriesStart: '' })
        }

    }, [parkingGroup.isNumbered, parkingGroup.numberSeriesStart, parkingGroup.numberOfParkingSpots]);

    const handleNumberOfParkingSpotsChange = (value) => {
        if (value) {
            var regex = /[^0-9]|\./;
            setParkingGroup({ ...parkingGroup, numberOfParkingSpots: parseInt(value.replace(regex, '')) });
        } else {
            setParkingGroup({ ...parkingGroup, numberOfParkingSpots: '' });
        }
    };

    const handleNumberSeriesChange = (value) => {
        if (value) {
            var regex = /[^0-9]|\./;
            setParkingGroup({ ...parkingGroup, numberSeriesStart: parseInt(value.replace(regex, '')) });
        } else {
            setParkingGroup({ ...parkingGroup, numberSeriesStart: 0 });
        }
    };

    const handleSaveParkingGroup = () => {
        let updatedParkingGroups = parkingArea.parkingGroups;

        if (parkingGroup.numberOfParkingSpots == '') {
            parkingGroup.numberOfParkingSpots = 1;
        }

        if (parkingGroup.isNumbered && !Number.isInteger(parkingGroup.numberSeriesStart)) {
            parkingGroup.isNumbered = false;
        }

        if (!Number.isInteger(parkingGroup.numberSeriesStart)) {
            parkingGroup.numberSeriesStart = 0;
        }

        updatedParkingGroups.push(parkingGroup);
        setParkingArea({ ...parkingArea, parkingGroups: updatedParkingGroups });
        setDisplay(false);
    }

    if (display) {
        return (
            <>
                {/* Overlay */}
                <div className="fixed inset-0 z-10 bg-black bg-opacity-60">
                </div>
                {/* Modal */}
                <div onClick={(e) => handleOverlayOnClick(e)} className="flex fixed inset-0 z-20 justify-center items-center overflow-y-auto overflow-x-hidden">
                    <div onClick={(e) => e.stopPropagation()} className="flex max-w-sm w-full p-3 justify-center items-center">
                        <div className="flex flex-col w-full bg-white max-w-sm rounded-lg p-6">
                            <div className="flex justify-end">
                                <button className="h-4" onClick={() => { setDisplay(false) }}>
                                    <CloseIcon className="h-5 w-5" />
                                </button>
                            </div>
                            <div className="flex flex-row mt-6 mb-3">
                                <div className="flex items-center flex-row w-1/2 mr-2">
                                    <RadioButton isChecked={!parkingGroup.isIndoor} onClick={(e) => { setParkingGroup({ ...parkingGroup, isIndoor: false }) }} />
                                    <SunIcon className="ml-2" />
                                    <span className="ml-2">{t('createParkingArea.outdoors')}</span>
                                </div>
                                <div className="flex items-center flex-row w-1/2">
                                    <RadioButton isChecked={parkingGroup.isIndoor} onClick={(e) => { setParkingGroup({ ...parkingGroup, isIndoor: true }) }} />
                                    <GarageIcon className="ml-2 w-8" />
                                    <span className="ml-2">{t('createParkingArea.indoors')}</span>
                                </div>
                            </div>
                            <span className="flex mt-4 font-medium">{t('createParkingArea.features')}</span>
                            <div className="flex mt-2">
                                <ButtonToggle defaultIcon={<ElectricityIconBlack />} toggledIcon={<ElectricityIconWhite />} isToggled={parkingGroup.isChargingPost} title={t('createParkingArea.chargingPost')} onClick={() => { setParkingGroup({ ...parkingGroup, isChargingPost: !parkingGroup.isChargingPost }) }} />
                                <ButtonToggle defaultIcon={<FenceIconBlack />} toggledIcon={<FenceIconWhite />} isToggled={parkingGroup.isFenced} title={t('createParkingArea.fenced')} onClick={() => { setParkingGroup({ ...parkingGroup, isFenced: !parkingGroup.isFenced }) }} className="ml-2" />
                            </div>
                            <div className="flex items-center mt-4">
                                <ButtonSlider title={t('createParkingArea.floor')}
                                              isToggled={showFloor}
                                              onClick={() => {
                                                  const enabled = !showFloor;
                                                  if (!enabled) {
                                                      setParkingGroup({ ...parkingGroup, floor: null })
                                                  }
                                                  setShowFloor(enabled);
                                              }}/>
                                <div className='ml-3'>
                                    <InputNumber value={parkingGroup.floor}
                                                 disabled={!showFloor}
                                                 onChange={(value) => {
                                                     setParkingGroup({ ...parkingGroup, floor: value })
                                                 }}/>
                                </div>
                            </div>
                            <div className="flex flex-col mt-4">
                                <InputNumber title={t('createParkingArea.amount')}
                                             value={parkingGroup.numberOfParkingSpots} onChange={(value) => {
                                    handleNumberOfParkingSpotsChange(value);
                                }}/>
                            </div>

                            <div className="flex flex-col mt-4">
                                <ToggleText title={t('createParkingArea.numberedTitle')}
                                            defaultText={t('createParkingArea.notnumbered')}
                                            toggledText={t('createParkingArea.numbered')}
                                            isToggled={parkingGroup.isNumbered} onClick={() => {
                                    setParkingGroup({ ...parkingGroup, isNumbered: !parkingGroup.isNumbered })
                                }}/>
                            </div>
                            <CollapsibleContainer isExpanded={parkingGroup.isNumbered}>
                                {parkingGroup.isNumbered &&
                                    <div className="flex flex-row mt-3">
                                        <div className="flex flex-col w-1/2 pr-1">
                                            <span className="font-medium">{t('createParkingArea.from')}</span>
                                            <input type="number" onChange={(e) => { handleNumberSeriesChange(e.target.value); }} className="max-w-full rounded-xl p-2 pl-4 bg-airpark-gray-300 focus:outline-none"></input>
                                        </div>
                                        <div className="flex flex-col w-1/2 pl-1">
                                            <span className="font-medium">{t('createParkingArea.to')}</span>
                                            <input type="number" value={parkingGroup.numberSeriesEnd} disabled={true} className="max-w-full rounded-xl p-2 pl-4 bg-airpark-gray-300 opacity-50 focus:outline-none"></input>
                                        </div>
                                    </div>
                                }
                            </CollapsibleContainer>
                            <ButtonText onClick={() => { handleSaveParkingGroup(); }} className="flex mt-6 justify-center w-full">
                                {t('createParkingArea.add')}
                            </ButtonText>
                        </div>
                    </div>
                </div>
            </>)
    } else {
        return (null);
    }
}

export default AddParkingSpotsModal;