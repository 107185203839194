import React, { useState, useContext } from "react";

export const MessagesContext = React.createContext({});
export const useMessages = () => useContext(MessagesContext);

export const MessagesProvider = ({ children }) => {
  const [toast, setToast] = useState({ display: false, title: '', description: '', template: '' });
  const [modal, setModal] = useState(
    {
      display: false,
      title: '',
      description: '',
      customRender: null,
      primaryButtonTitle: '',
      primaryButtonOnClick: () => { },
      secondaryButtonTitle: '',
      secondaryButtonOnClick: () => { },
      template: '',
      loading: false
    });
    
  const [betweenPagesMessage, setBetweenPagesMessage] = useState({ display: false, title: '', description: '', buttonTitle: '', template: '', onClick: () => { } });

  const showToast = (title, description, template) => {
    if (toast.display) {
      // HACK: trigger state change if the toast has been shown
      setToast({ display: false });

      setTimeout(() => {
        setToast({ display: true, title: title, description: description, template: template });
      }, 50);

    } else {
      setToast({ display: true, title: title, description: description, template: template });
    }
  }

  const hideToast = () => {
    setToast({ ...toast, display: false });
  }

  const showModal = (title, description, primaryButtonTitle, primaryButtonOnClick, secondaryButtonTitle, secondaryButtonOnClick, template, customRender = null) => {
    setModal({ display: true, title: title, description: description, primaryButtonTitle: primaryButtonTitle, primaryButtonOnClick: primaryButtonOnClick, secondaryButtonTitle: secondaryButtonTitle, secondaryButtonOnClick: secondaryButtonOnClick, template: template, customRender });
  }

  const hideModal = () => {
    setModal({ ...modal, display: false });
  }

  const showBetweenPagesMessage = (title, description, onClick, template) => {
    setBetweenPagesMessage({ display: true, title: title, description: description, template: template, onClick: onClick });
  }

  const hideBetweenPagesMessage = () => {
    setBetweenPagesMessage({ ...betweenPagesMessage, display: false });
  }

  const showBetweenPagesLoading = (title) => {
    setBetweenPagesMessage({ display: true, title: title ? title : '', description: '', template: 'loading', onClick: () => { } })
  }

  const hideBetweenPagesLoading = () => {
    setBetweenPagesMessage({ ...betweenPagesMessage, display: false })
  }

  return (
    <MessagesContext.Provider
      value={{
        toast,
        modal,
        betweenPagesMessage,
        showToast,
        hideToast,
        showModal,
        hideModal,
        showBetweenPagesMessage,
        hideBetweenPagesMessage,
        showBetweenPagesLoading,
        hideBetweenPagesLoading
      }}>
      {children}
    </MessagesContext.Provider>
  );
}