import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowDownIcon } from "assets/icons/ico_arrow_down_green.svg";
import CheckBox from '../checkbox';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'recharts';

const DropDownMultiSelector = ({ options, onOptionSelected, title, tooltip,label, minWidth = '320px', wFull = false }) => {

    const [showDropDown, setShowDropDown] = useState(false);
    const [numberOfOptionsSelected, setNumberOfOptionsSelected] = useState(options.length);
    const { t } = useTranslation();

    useEffect(() => {
        const numberOfOptionsSelected = options.filter((option) => { return option.isChecked }).length;
        setNumberOfOptionsSelected(numberOfOptionsSelected);
    }, [options])

    return (
        <div className={`flex flex-col relative w-full ${!wFull && 'md:w-max'}`}>
            {title &&
                <div className="flex relative items-center">
                    <span className="text-base font-medium pb-1">{title}</span>
                    {tooltip &&
                        <Tooltip tooltip={tooltip} />
                    }
                </div>
            }
            <button onClick={(e) => { e.preventDefault(); setShowDropDown(!showDropDown); }} className={`flex items-center relative bg-airpark-gray-300 rounded-xl h-11 appearance-none pl-6 min-w-[200px] md:min-w-[${minWidth}]`}>
                <div className="flex w-full justify-between items-center pr-10">
                <span>{label !== undefined && label !== null ? label : t('dropDownMultiSelector.filter')}</span>
                {numberOfOptionsSelected > 0 &&
                        <span>({numberOfOptionsSelected})</span>
                    }
                </div>
                <div className="flex absolute right-4 top-4 pointer-events-none">
                    <ArrowDownIcon />
                </div>
            </button>
            {showDropDown && options &&
                <>
                    <div className="fixed inset-0 z-10" onClick={(e) => { e.preventDefault(); setShowDropDown(false); }} />
                    <ul className={`flex flex-col z-20 absolute ${title ? 'top-20' : 'top-12'} p-3 bg-white shadow min-w-[200px] md:min-w-[${minWidth}] rounded-xl`}>
                        {options.map((option, i) => {
                            return (
                                <li key={i} className="flex mb-2 last:mb-0">
                                    <button className="flex" style={{opacity: option.isDisabled ? 0.2 : 1}} onClick={(e) => {if(option.isDisabled) return; e.preventDefault(); onOptionSelected(option); }}>
                                        <div className="flex pointer-events-none">
                                            <CheckBox isChecked={option.isChecked} />
                                            <span className="ml-2">{option.title}</span>
                                        </div>
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                </>
            }
        </div>
    );
};

export default DropDownMultiSelector;