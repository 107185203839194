
import React, { useEffect, useState } from "react";

import { useDashboard } from "context/dashboard";
import { getToken } from "context/auth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMessages } from "context/messages";

import { getParkingAreasForOrganization } from "api/locations";

import WidgetLoadingSkeleton from "components/dashboard/widgetLoadingSkeleton";
import Alert from "components/common/alert";
import ButtonText from "components/common/buttonText";
import Widget from "components/dashboard/widget";
import ParkingAreasTable from "components/pageSpecific/parkingAreas/parkingAreasTable";
import VideoInstructions from "components/common/videoInstructions";

const ParkingAreasPage = () => {

    const { organization, loading } = useDashboard();
    const [parkingAreas, setParkingAreas] = useState();
    const [loadingParkingAreas, setLoadingParkingAreas] = useState();
    const navigate = useNavigate();
    const { showToast } = useMessages();
    const { t } = useTranslation();

    useEffect(() => {
        const getParkingAreas = async () => {
            setLoadingParkingAreas(true);

            try {
                const token = await getToken();
                const parkingAreasResponse = await getParkingAreasForOrganization(token, organization.organizationExtId.toLowerCase());
                setParkingAreas(parkingAreasResponse);
            } catch (error) {
                console.error(error);
                showToast(t('generic.error'), t('generic.errorDescription'), 'error');
            }
            
            setLoadingParkingAreas(false);
        }
        
        getParkingAreas();
    }, []);


    if (!loading) {
        return (
            <>
                {loadingParkingAreas &&
                    <WidgetLoadingSkeleton />
                }
                {!loadingParkingAreas &&
                    <>
                        {parkingAreas && parkingAreas.length == 0 &&
                            <div className="mb-6">
                                <Alert title={t('parkingAreas.noAreasTitle')} description={t('parkingAreas.noAreasDescription')} template='info' />
                            </div>
                        }
                        <div className="mb-6">
                            <VideoInstructions 
                                title={t('generic.videoInstructions')}
                                description={t('parkingAreas.videoInstructionsDescription')}
                                videoUrl='https://www.youtube.com/watch?v=GxCptXuiKjs' />
                        </div>
                        <Widget>
                            <div className="flex items-center justify-between mb-6">
                                <h2 className="text-xl font-medium">{t('parkingAreas.areas')}</h2>
                                <ButtonText onClick={() => { navigate(`/${organization.organizationExtId.toLowerCase()}/create-parking-area/area`) }}>
                                    {t('parkingAreas.addArea')}
                                </ButtonText>
                            </div>
                            <ParkingAreasTable parkingAreas={parkingAreas} setParkingAreas={setParkingAreas} />
                        </Widget>
                    </>
                }

            </>);
    } else {
        return (null);
    }

}

export default ParkingAreasPage;