import Widget from "components/dashboard/widget";
import ButtonText from "components/common/buttonText";
import InputSearch from "components/common/inputSearch";
import { useTranslation } from "react-i18next";
import OrganizationUsersTable from "./organizationUsersTable";

const OrganizationUsersTableWithControls = ({
    searchText,
    setSearchText,
    setDisplayInviteUserModal,
    filteredAdmins,
    filteredSublessors,
    setFilteredAdmins,
    setFilteredSublessors,
    title,
}) => {
    const { t } = useTranslation();
    
    return (
        <Widget className="mt-6">
            <div className="flex md:items-center items-start flex-col md:flex-row justify-between mb-6 gap-3">
                <h1 className="text-xl font-medium">{title}</h1>
                <div className="flex items-center gap-3">
                    <InputSearch
                        placeHolder={t('generic.search')}
                        value={searchText ?? ''}
                        onChange={(value) => { setSearchText(value); }} />
                    <ButtonText onClick={() => { setDisplayInviteUserModal(true); }}>
                        {t('generic.invite')}
                    </ButtonText>
                </div>
            </div>
            <OrganizationUsersTable 
                filteredAdmins={filteredAdmins}
                filteredSublessors={filteredSublessors}
                setFilteredAdmins={setFilteredAdmins}
                setFilteredSublessors={setFilteredSublessors} />
        </Widget>
    )
}

export default OrganizationUsersTableWithControls;