import React, { useEffect, useState } from "react";
import Widget from "components/dashboard/widget";
import { useTranslation } from "react-i18next";
import { ComposedChart, XAxis, YAxis, Legend, ResponsiveContainer, Tooltip, Bar, Cell, Line } from 'recharts';
import InputDate from "components/common/inputDate";
import DropdownSelector from "components/common/dropdownSelector/dropdownSelector";
import DropDownMultiSelector from "components/common/dropDownMultiSelector";
import { getParkingAreasForOrganization } from "api/locations";
import { getToken } from "context/auth";
import WidgetLoadingSkeleton from "components/dashboard/widgetLoadingSkeleton";
import { useParams } from "react-router-dom";
import { useMessages } from "context/messages";
import { formatCurrency } from "helpers/currency";
import { useDashboard } from "context/dashboard";
import { getDailyRevenue, getMonthlyRevenue } from "api/statistics";

const StatisticsPage = () => {
    const { t } = useTranslation();
    const { organizationExtId } = useParams();
    const { organization } = useDashboard();

    // Date should be the first day of the current month
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));

    // Date should be the last of the current month
    const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
    const [selectedParkingAreas, setSelectedParkingAreas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedInterval, setSelectedInterval] = useState('day');

    const { showToast } = useMessages();
    const [selectedTransactionTypes, setSelectedTransactionTypes] = useState([
        { value: 'Bookings', title: t('statisticsPage.bookings'), isChecked: true },
        { value: 'Permits', title: t('statisticsPage.permits'), isChecked: true },
        { value: 'ControlFee', title: t('statisticsPage.controlfees'), isChecked: true },
        { value: 'Sublet booking', title: t('statisticsPage.subletbookings'), isChecked: true },
        { value: 'Charging', title: t('statisticsPage.charging'), isChecked: true },
        { value: 'Total', title: t('statisticsPage.total'), isChecked: true }
    ]);

    const [statistics, setStatistics] = useState({
        keys: {},
        data: []
    });

    const colorMap = {
        'Bookings': '#33D085',       
        'Permits': '#2886FF',        
        'ControlFee': '#FF899E',   
        'Sublet booking': '#FFA500', 
        'Charging': '#FABB05',      
        'Total': '#17BB6C'
    };

    useEffect(() => {
        const load = async () => {
            try {
                const token = await getToken();
                const result = await getParkingAreasForOrganization(token, organizationExtId);

                if (!result?.error) {
                    let parkingAreas = [];

                    for (const parkingArea of result) {
                        parkingAreas.push({
                            value: parkingArea.parkingAreaExtId,
                            title: parkingArea.name,
                            isChecked: true
                        })
                    }

                    setSelectedParkingAreas(parkingAreas);
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
            }

            setLoading(false);
        }
        load();
    }, []);

    useEffect(() => {
        const load = async () => {
            try {
                const token = await getToken();
    
                const request = {
                    organizationExtId: organizationExtId,
                    fromDate: startDate,
                    toDate: endDate,
                    parkingAreaExtIds: selectedParkingAreas.filter((item) => item.isChecked).map((item) => item.value),
                    transactionTypes: selectedTransactionTypes.filter((item) => item.isChecked).map((item) => item.value)
                };
    
                let result;
    
                if (selectedInterval === 'day') {
                    result = await getDailyRevenue(token, request);
                } else if (selectedInterval === 'month') {
                    result = await getMonthlyRevenue(token, request);
                }
    
                if (result?.error) {
                    console.error('Error fetching data:', result.error);
                    return;
                }
    
                const dataMapping = result.reduce((acc, item) => {
                    var key;
                    if (selectedInterval === 'day') {
                        key = new Date(item.day).toISOString().substring(0, 10);
                    }

                    if (selectedInterval === 'month') {
                        key = new Date(item.month).toISOString().substring(0, 7);
                    }

                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push({
                        date: key,
                        revenue: item.revenue,
                        transactionType: item.transactionType
                    });
                    return acc;
                }, {});
    
                const allDates = [];
                let currentDate = new Date(startDate);
                while (currentDate <= new Date(endDate)) {
                    allDates.push(new Date(currentDate));
                    if (selectedInterval === 'day') {
                        currentDate.setDate(currentDate.getDate() + 1);
                    } else {
                        currentDate.setMonth(currentDate.getMonth() + 1);
                    }
                }
    
                const mergedData = allDates.map(date => {
                    const dateString = date.toISOString().substring(0, selectedInterval === 'day' ? 10 : 7);
                    return dataMapping[dateString] || [{ date: dateString, revenue: 0, transactionType: 'No Data' }];
                }).flat();

                setStatistics({ data: mergedData });
            } catch (error) {
                console.error('Failed to load data:', error);
            }
        };
    
        load();
    }, [startDate, endDate, selectedParkingAreas, selectedTransactionTypes, organizationExtId, getToken, selectedInterval]);
    

    const renderTranslatedLegend = (value) => {
        return <span>{t(`legend.${value}`)}</span>;
    }

    const handleParkingAreaOptionSelected = (option) => {
        const newOptions = selectedParkingAreas.map((item) => {
            if (item.value === option.value) {
                return { ...item, isChecked: !item.isChecked };
            }
            return item;
        });
        setSelectedParkingAreas(newOptions);
    }

    const handleTransactionTypeOptionSelected = (option) => {
        const newOptions = selectedTransactionTypes.map((item) => {
            if (item.value === option.value) {
                return { ...item, isChecked: !item.isChecked };
            }
            return item;
        });
        setSelectedTransactionTypes(newOptions);
    }

    const customXAxisTickFormatter = (date) => {
        if (date && selectedInterval === 'day') {
            return `${date.substr(8, 2)}/${date.substr(5, 2)}`;
        } else if (date && selectedInterval === 'month') {
            return `${date.substr(5, 2)}/${date.substr(0, 4)}`;
        }

        return 'no date';
    }

    const customYAxisTickFormatter = (amount) => {
        return `${Math.ceil(amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            // Extracting the transaction type from the payload if it's part of your data setup
            const transactionType = payload[0].payload.transactionType; // Ensure 'transactionType' is being passed along in your payload
    
            // Custom message or style based on transaction type
            let customMessage = '';
            let customStyle = 'text-airpark-green'; // default style

            switch (transactionType) {
                case 'Bookings':
                    customMessage = 'Booking Revenue';
                    customStyle = 'text-airpark-green'; // Example of changing color style
                    break;
                case 'Permits':
                    customMessage = 'Permit Revenue';
                    customStyle = 'text-airpark-sea';
                    break;
                case 'ControlFee':
                    customMessage = 'Control Fee Revenue';
                    customStyle = 'text-pink-500';
                    break;
                case 'Total':
                    customMessage = 'Total Revenue';
                    customStyle = 'text-airpark-green-400';
                    break;
                case 'Charging':
                    customMessage = 'Charging Revenue';
                    customStyle = 'text-yellow-500';
                    break;
                case 'Sublet booking':
                    customMessage = 'Sublet Bookings Revenue';
                    customStyle = 'text-orange-500';
                    break;

                default:
                    customMessage = transactionType;
                    customStyle = 'text-gray-500';
            }
    
            return (
                <div className="shadow-widget p-6 rounded-xl bg-white">
                    <p className="font-medium">{label}</p>
                    <p className={`label ${customStyle}`}>{`${t(customMessage)}: ${formatCurrency(payload[0].value)} ${organization.currencySymbol}`}</p>
                </div>
            );
        }
    
        return null;
    };
    
    return (
        <div className="flex flex-col gap-y-6">
    <Widget>
        <div className="flex gap-12 justify-between items-center flex-wrap">
            <div className="flex flex-col">
                <h1 className="text-xl font-medium mb-1">{t('statisticsPage.title')}</h1>
                <span>{t('statisticsPage.description')}</span>
            </div>
        </div>
    </Widget>
    {loading &&
        <WidgetLoadingSkeleton />
    }
    {!loading && statistics && selectedParkingAreas &&
        <Widget>
            <div className="flex flex-col mb-6">
                <h2 className="text-xl font-medium">{t('statisticsPage.revenue')}</h2>
                <span></span>
            </div>
            <div className="flex flex-col gap-y-6">
                <div className="flex flex-row gap-3 flex-wrap">
                    <InputDate 
                        value={startDate} 
                        onChange={(value) => { setStartDate(value); }} 
                        title={t('generic.from')} 
                        className="w-full sm:w-auto"
                    />
                    <InputDate 
                        value={endDate} 
                        onChange={(value) => { setEndDate(value); }} 
                        title={t('generic.to')} 
                        className="w-full sm:w-auto"
                    />
                </div>

                <div className="flex flex-row gap-3 flex-wrap">
                    <DropDownMultiSelector
                        title={t('statisticsPage.chooseParkingAreas')}
                        options={selectedParkingAreas}
                        onOptionSelected={handleParkingAreaOptionSelected}
                        className="w-full sm:w-auto"
                    />
                    <DropDownMultiSelector
                        title={t('statisticsPage.chooseRevenueType')}
                        options={selectedTransactionTypes}
                        onOptionSelected={handleTransactionTypeOptionSelected}
                        className="w-full sm:w-auto"
                    />
                    <DropdownSelector
                        title={t('statisticsPage.chooseInterval')}
                        options={[{ value: 'day', title: t('generic.day') }, { value: 'month', title: t('generic.month') }]}
                        onChange={(value) => { setSelectedInterval(value); }}
                        className="w-full sm:w-auto"
                    />
                </div>
            </div>
            <div className="mt-6 min-h-[420px]">
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart width={300} height={200} data={statistics.data}>
                        <Bar dataKey="revenue">
                            {statistics.data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colorMap[entry.transactionType] || '#DDDDDD'} />
                            ))}
                        </Bar>
                        <XAxis tickFormatter={customXAxisTickFormatter} dataKey="date" />
                        <YAxis tickFormatter={customYAxisTickFormatter} unit={organization?.currencySymbol} width={80} />
                        <Legend formatter={renderTranslatedLegend} />
                        <Tooltip content={<CustomTooltip />} />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </Widget>
    }
</div>
    )
}

export default StatisticsPage;